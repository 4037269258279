<template>
  <div class="container-dashboard q-pa-md col-12">
    <Mural :breadcrumbs="breadcrumbs" route="home" :query="{}"> </Mural>

    <div class="col-12 flex row q-pa-sm">
      <div class="form-input col-3 column" v-show="isMaster">
        <div class="row">
          <span class="title-input-form">Empresa</span>
        </div>
        <div class="row">
          <q-select
            outlined
            bg-color="grey-3"
            color="default-pink"
            class="col-11"
            dense
            emit-value
            map-options
            v-model="filter.company_id"
            :options="listCompanies"
          >
            <template v-slot:no-option>
              <q-item>
                <q-item-section class="text-grey">
                  Não encontrado
                </q-item-section>
              </q-item>
            </template>
          </q-select>
        </div>
      </div>
      <div class="form-input col-3 column">
        <div class="row q-mt-lg">
          <DefaultBtn
            :isDense="true"
            :isInverse="true"
            :showIcon="false"
            :hasPadding="false"
            class="q-ml-sm"
            titleButton="Atualizar"
            @click="getFilterDashboard()"
          />
        </div>
      </div>
    </div>

    <div class="col-12 flex row q-mt-sm">
      <q-card class="card-top q-ma-sm card-top-orange">
        <q-card-section class="q-pb-none">
          <div class="text-subtitle2">Aderência aos conteúdos dos cursos</div>
        </q-card-section>
        <q-card-section class="q-pa-none q-mt-sm q-ml-lg">
          <div class="card-top-title">
            {{
              percentage_adherence_course == "null%"
                ? "-"
                : percentage_adherence_course
            }}
          </div>
        </q-card-section>
      </q-card>

      <q-card class="card-top q-ma-sm card-top-red">
        <q-card-section class="q-pb-none">
          <div class="text-subtitle2">Assertividade nas avaliações</div>
        </q-card-section>
        <q-card-section class="q-pa-none q-mt-sm q-ml-lg">
          <div class="card-top-title">
            {{ percentage_assertiveness_evaluation }}
          </div>
        </q-card-section>
      </q-card>

      <q-card class="card-top q-ma-sm card-top-purple">
        <q-card-section class="q-pb-none">
          <div class="text-subtitle2">Assertividade dos quiz</div>
        </q-card-section>
        <q-card-section class="q-pa-none q-mt-sm q-ml-lg">
          <div class="card-top-title">{{ percentage_assertiveness_quiz }}</div>
        </q-card-section>
      </q-card>

      <q-card class="card-top q-ma-sm card-top-green">
        <q-card-section class="q-pb-none">
          <div class="text-subtitle2">Certificados emitidos</div>
        </q-card-section>
        <q-card-section class="q-pa-none q-mt-sm q-ml-lg">
          <div class="card-top-title">{{ number_students_completed }}</div>
        </q-card-section>
      </q-card>
    </div>
    <div class="col-12 flex row q-pt-sm q-mt-sm">
      <q-card class="col-12 card-table" style="height: 430px">
        <q-card-section class="q-pa-sm col-12">
          <Table
            class="full-height"
            :list="rows_table_course"
            :columns="columns_table_course"
            titleTable="Cursos"
            :addEdit="false"
            :addRemove="false"
            :addView="false"
          />
        </q-card-section>
      </q-card>
    </div>
    <div class="col-12 flex row q-py-sm q-mt-md">
      <div class="col-12 row">
        <div class="col-9">
          <q-card class="card-table" style="height: 350px">
            <q-card-section class="q-pa-none col-12">
              <q-btn color="default-pink" :label="labelGraphCurrent" flat>
                <q-icon name="keyboard_arrow_down" />
                <q-menu>
                  <q-list style="min-width: 200px">
                    <q-item
                      clickable
                      v-close-popup
                      @click="changeDataOfGraph('post')"
                    >
                      <q-item-section>Post</q-item-section>
                    </q-item>
                    <q-item
                      clickable
                      v-close-popup
                      @click="changeDataOfGraph('chat')"
                    >
                      <q-item-section>Chat</q-item-section>
                    </q-item>
                    <q-separator />
                  </q-list>
                </q-menu>
              </q-btn>

              <div class="col-12">
                <apexchart
                    v-if="options?.xaxis?.categories.length > 0"
                  height="300px"
                  :width="width_charts"
                  type="line"
                  :options="options"
                  :series="series"
                >
                </apexchart>
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col-3">
          <q-card class="q-ml-sm card-table q-pa-md" style="height: 350px">
            <div class="title-card q-mt-sm">Usuários</div>
            <div class="row q-mt-sm q-ml-sm">
              <div class="subtitle-card q-mt-md">Ativos</div>
              <div class="subtitle-card q-mt-sm">
                {{ number_user_active }} usuários
                <div class="q-mt-sm">
                  <img src="../../assets/images/graph_vetor_green.svg" />
                </div>
              </div>
            </div>

            <div class="row q-mt-xl q-ml-sm">
              <div class="subtitle-card q-mt-md">Inativos</div>
              <div class="subtitle-card q-mt-sm">
                {{ number_user_inactive }} usuários
                <div class="q-mt-sm">
                  <img src="../../assets/images/graph_vetor_red.svg" />
                </div>
              </div>
            </div>
          </q-card>
        </div>
      </div>
    </div>
    <div class="col-12 flex row q-py-sm">
      <q-card class="card-table">
        <q-card-section class="col-12 row">
          <div class="top-ranking col-12 row flex">
            <div class="flex row col-6">
              <div class="col-12 row flex">
                <div class="col-6 row flex">
                  <div class="col-12 title-card row justify-center">
                    <label>Top 1 estudante</label>
                  </div>
                  <div class="flex col-12 row justify-center q-pb-md">
                    <q-avatar size="25vh">
                      <q-img
                        class="avatar"
                        :src="url_aws_bucket + studant_top_1.avatar_location"
                        spinner-color="white"
                      >
                        <template v-slot:error>
                          <q-icon
                            class="avatar"
                            name="account_circle"
                            size="40px"
                          />
                        </template>
                      </q-img>
                    </q-avatar>
                  </div>
                  <div class="flex col-12 row justify-center">
                    <h4>{{ studant_top_1.user_name }}</h4>
                  </div>
                </div>
                <div class="col-6 flex">
                  <div class="flex col-12 row items-center">
                    <div class="column col-12">
                      <h4>
                        Cursos realizados:
                        {{ studant_top_1.number_courses_completed }}
                      </h4>
                      <h4>
                        Cursos iniciados:
                        {{ studant_top_1.number_courses_started }}
                      </h4>
                      <h4>
                        Interações Post:
                        {{ studant_top_1.number_posts_interacted }}
                      </h4>
                      <h4>
                        Wiki realizados:
                        {{ studant_top_1.number_wiki_completed }}
                      </h4>
                      <h4>
                        Messagens chat:
                        {{ studant_top_1.number_chat_menssages }}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex row col-6">
              <div class="col-12 title-card row justify-center">
                <label>Top 5 estudantes de interação no chat</label>
              </div>
              <div class="flex col-12 list-ranking row">
                <div
                  class="col-6 row"
                  v-for="item in studant_ranking"
                  :key="item.user_name"
                >
                  <div>
                    <h4>
                      <b>{{ item.sequence_id }}º .</b>{{ item.user_name }}
                    </h4>
                    <label
                      >Messagens chat: {{ item.number_interactions }}</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </div>
    <div class="col-12 flex row q-py-sm">
      <card-horizontal-simple
        title="Quantidade de interações dos estudantes"
        :items="number_of_sudent_interactions"
      ></card-horizontal-simple>
    </div>
    <div class="col-12 flex row q-py-sm">
      <card-horizontal-simple
        title="Quantidade de interações dos administradores"
        :items="number_interactions_administrators"
      ></card-horizontal-simple>
    </div>

    <q-inner-loading
      :showing="loading"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
const columns_table_course = [
  {
    name: "course_id",
    align: "left",
    label: "ID",
    field: "course_id",
    sortable: true,
  },
  {
    name: "title",
    label: "Título do curso",
    align: "left",
    field: "title",
    format: (val) => `${val}`,
    sortable: true,
  },
  {
    name: "name_tutor",
    align: "left",
    label: "Tutor",
    field: "name_tutor",
    sortable: true,
  },
  {
    name: "number_students_registered",
    align: "left",
    label: "Estudantes  registrados",
    field: "number_students_registered",
  },
  {
    name: "number_students_started",
    align: "left",
    label: "Estudantes iniciantes",
    field: "number_students_started",
  },
  {
    name: "number_students_completed",
    align: "left",
    label: "Estudantes concluintes",
    field: "number_students_completed",
  },
];

import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import DashboardServices from "@/services/DashboardServices";
import CardSimple from "@/components/Dashboard/CardSimple";
import CardHorizontalSimple from "../../components/Dashboard/CardHorizontalSimple.vue";
import CardTopRanking from "@/components/Dashboard/CardTopRanking.vue";
import CompanyServices from "@/services/CompanyServices";
import { date } from "quasar";
import Table from "@/components/shared/tables/TableDynamic.vue";
import { ENV } from "@/utils/env";
export default {
  components: {
    CardSimple,
    CardTopRanking,
    CardHorizontalSimple,
    Mural,
    DefaultBtn,
    Table,
  },
  setup() {
    let url_aws_bucket = ENV.URL_AWS_BUCKET;
    let breadcrumbs = ref(["Dashboard"]);
    let filter = reactive({
      company_id: null,
      initial_date: new Date(),
      final_date: new Date(),
    });
    let loading = ref(false);
    let listCompanies = ref([]);

    let studant_top_1 = reactive({
      user_name: "",
      number_courses_completed: "",
      number_courses_started: "",
      number_posts_interacted: "",
      number_wiki_completed: "",
      number_chat_menssages: "",
      avatar_location: "",
    });
    let studant_ranking = ref([]);
    let _companyServices = new CompanyServices();
    const dashboardServices = new DashboardServices();
    const store = useStore();
    let width_charts = ref(500);
    let date_initial = ref("");
    let date_final = ref("");
    let rows_table_course = ref([]);
    let number_user_active = ref("-");
    let number_user_inactive = ref("-");
    let number_students_completed = ref("-");
    let percentage_adherence_course = ref("-");
    let percentage_assertiveness_quiz = ref("-");
    let percentage_assertiveness_evaluation = ref("-");
    let number_of_sudent_interactions = ref([]);
    let number_interactions_administrators = ref([]);
    let student_ranking_table_top1_student = ref([]);
    let labelGraphCurrent = ref("");
    let options = ref({});
    let optionsPost = reactive({
      chart: {
        id: "vuechart-example",
        toolbar: {
          show: true,
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
            selection: false,
            pan: false,
            reset: false,
          },
        },
      },
      stroke: {
        width: 2,
      },
      legend: {
        showForSingleSeries: true,
        horizontalAlign: "center",
        markers: {
          width: 60,
          height: 3,
        },
      },
      xaxis: {
        categories: [],
      },
    });

    let optionsChat = reactive({
      chart: {
        id: "vuechart-example",
        toolbar: {
          show: true,
          tools: {
            zoom: false,
            zoomin: false,
            zoomout: false,
            selection: false,
            pan: false,
            reset: false,
          },
        },
      },
      stroke: {
        width: 2,
      },
      legend: {
        showForSingleSeries: true,
        horizontalAlign: "center",
        markers: {
          width: 60,
          height: 3,
        },
      },
      xaxis: {
        categories: [],
      },
    });
    let series = ref([]);
    let seriesPosts = ref([
      {
        name: "Likes",
        data: [],
      },
      {
        name: "Deslikes",
        data: [],
      },
      {
        name: "Comentários",
        data: [],
      },
    ]);

    let seriesChat = ref([
        {
          name: "Membros",
          data: [],
        },
        {
          name: "Mensagens",
          data: [],
        }
  ]);

    let userData = ref(null);
    let isMaster = ref(false);

    async function getAdherenceToCourse() {
      await dashboardServices
        .adherenceToCourse(filter)
        .then((response) => {
          number_students_completed.value = response.number_students_completed;
          percentage_adherence_course.value =
            Math.trunc(response.percentage_adherence_course) + "%";
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    async function getAssertivenessInAssessments() {
      await dashboardServices
        .assertivenessInAssessments(filter)
        .then((response) => {
          for (let i = 0; i < response.length; i++) {
            if (response[i].evaluation) {
              if (response[i].percentage_assertiveness != null) {
                percentage_assertiveness_evaluation.value =
                  Math.round(response[i].percentage_assertiveness, -2) + "%";
              } else {
                percentage_assertiveness_evaluation.value = "-";
              }
            } else {
              if (response[i].percentage_assertiveness != null) {
                percentage_assertiveness_quiz.value =
                  Math.round(response[i].percentage_assertiveness, -2) + "%";
              } else {
                percentage_assertiveness_quiz.value = "-";
              }
            }
          }
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    async function getReportAdherenceCourse() {
      await dashboardServices
        .reportAdherenceCourse(filter)
        .then((response) => {
          rows_table_course.value = response;
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    async function getStudentRankingTableTop1Student() {
      await dashboardServices
        .studentRankingTableTop1Student(filter)
        .then((response) => {
          studant_top_1.user_name = response[0].user_name;
          studant_top_1.number_courses_completed =
            response[0].number_courses_completed;
          studant_top_1.number_courses_started =
            response[0].number_courses_started;
          studant_top_1.number_posts_interacted =
            response[0].number_posts_interacted;
          studant_top_1.number_wiki_completed =
            response[0].number_wiki_completed;
          studant_top_1.number_chat_menssages =
            response[0].number_chat_menssages;
          studant_top_1.avatar_location = response[0].avatar_location;
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    async function getStudentRankingIntegrationsTop5Student() {
      await dashboardServices
        .studentRankingIntegrationsTop5Student(filter)
        .then((response) => {
          studant_ranking.value = response;
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    async function getNumberActiveInactiveUsers() {
      await dashboardServices
        .numberActiveInactiveUsers(filter)
        .then((response) => {
          number_user_active.value = response.number_active;
          number_user_inactive.value = response.number_inactive;
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    async function getNumberOfSudentInteractions() {
      await dashboardServices
        .numberOfSudentInteractions(filter)
        .then((response) => {
          number_of_sudent_interactions.value = response;
          let schema_number_of_sudent_interactions = {
            "Messagens chats":
              number_of_sudent_interactions.value.number_chat_menssages,
            "Cursos completos":
              number_of_sudent_interactions.value.number_courses_completed,
            "Cursos iniciados":
              number_of_sudent_interactions.value.number_courses_started,
            "Messagens nos grupos":
              number_of_sudent_interactions.value.number_groups_menssages,
            "Interações post":
              number_of_sudent_interactions.value.number_posts_interacted,
            "Quiz completos":
              number_of_sudent_interactions.value.number_quiz_completed,
            "Quiz iniciados":
              number_of_sudent_interactions.value.number_quiz_started,
            "Wiki completas":
              number_of_sudent_interactions.value.number_wiki_completed,
            "Wiki iniciadas":
              number_of_sudent_interactions.value.number_wiki_started,
          };
          number_of_sudent_interactions.value =
            schema_number_of_sudent_interactions;
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    async function getNumberInteractionsAdministrators() {
      await dashboardServices
        .numberInteractionsAdministrators(filter)
        .then((response) => {
          number_interactions_administrators.value = response;
          let schema_number_interactions_administrators = {
            "Cursos criados":
              number_interactions_administrators.value.number_courses_created,
            "Posts criados":
              number_interactions_administrators.value.number_posts_created,
            "Quiz criados":
              number_interactions_administrators.value.number_quiz_created,
            "Wiki criados":
              number_interactions_administrators.value.number_wikis_created,
          };
          number_interactions_administrators.value =
            schema_number_interactions_administrators;
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    async function getInteractionsPosts() {
      await dashboardServices
        .interactionsPosts(filter)
        .then((response) => {
          response.forEach((element) => {
            optionsPost.xaxis.categories.push(element.month_year_interaction);
            seriesPosts.value.forEach((post) => {
              if (post.name == "Likes") {
                post.data.push(element.total_likes);
              } else if (post.name == "Deslikes") {
                post.data.push(element.total_unlikes);
              } else if (post.name == "Comentários") {
                post.data.push(element.total_comments);
              }
            });
          });
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    function updateToGraphPost() {
      labelGraphCurrent.value = "Interações do Post";
      series.value = seriesPosts.value
      options.value = optionsPost;
    }

    async function getInteractionsChat() {
      await dashboardServices
        .interactionsChat(filter)
        .then((response) => {
          optionsChat.xaxis.categories = [];

          response.forEach((element) => {
            optionsChat.xaxis.categories.push(element.month_year_interaction);
            seriesChat.value.forEach((chat) => {
              if (chat.name == "Membros") {
                chat.data.push(element.number_members);
              } else if (chat.name == "Mensagens") {
                chat.data.push(element.number_number_messages);
              }
            });
          });
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    function updateToGraphChat() {
      labelGraphCurrent.value = "Interações do Chat";
      series.value = seriesChat.value;
      options.value = optionsChat;
    }

    onMounted(async () => {
      userData.value = JSON.parse(localStorage.getItem("vuex")).userdata;
      isMaster.value = userData.value.master == 1;
      filter.company_id = userData.value.company_id;
      await getInteractionsPosts();
      await getInteractionsChat();
      changeDataOfGraph("post")

      await getFilterDashboard();
      if (isMaster.value) {
        await getAllCompanies();
      }
      setTimeout(() => {
        width_charts.value = "100%";
      }, 1000);
    });

    async function getAllCompanies() {
      await _companyServices
        .getAll()
        .then((response) => {
          response.data.map((item) => {
            listCompanies.value.push({
              label: item.company_name,
              value: item.id,
            });
          });
        })
        .catch((e) => {
          loading.value = false;
        });
    }

    async function getFilterDashboard() {
      loading.value = true;



   await Promise.all([getAdherenceToCourse(),
        getAssertivenessInAssessments(),
        getReportAdherenceCourse(),
        getNumberActiveInactiveUsers(),
        getStudentRankingTableTop1Student(),
        getNumberOfSudentInteractions(),
        getStudentRankingIntegrationsTop5Student(),
        getNumberInteractionsAdministrators()])

      loading.value = false;
    }

    function changeDataOfGraph(typeGraph) {
      if (typeGraph === "post") {
        updateToGraphPost();
      } else if (typeGraph === "chat") {
        updateToGraphChat();
      }
    }

    return {
      filter: ref(""),
      url_aws_bucket,
      number_students_completed,
      percentage_adherence_course,
      percentage_assertiveness_evaluation,
      percentage_assertiveness_quiz,
      columns_table_course,
      rows_table_course,
      date_final,
      date_initial,
      number_of_sudent_interactions,
      number_interactions_administrators,
      number_user_active,
      number_user_inactive,
      student_ranking_table_top1_student,
      width_charts,
      breadcrumbs,
      filter,
      listCompanies,
      loading,
      studant_ranking,
      studant_top_1,
      getFilterDashboard,
      options,
      series,
      labelGraphCurrent,
      changeDataOfGraph,
      isMaster,
    };
  },
};
</script>
<style lang="scss">
.content {
  overflow-x: hidden;
}

.container-dashboard {
  border-radius: 10px !important;
  width: 100% !important;
  height: 100% !important;
  overflow-y: auto !important;
}

.title-dashboard {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}

.label-company {
  color: #c4c4c4;
}

.top-ranking {
  .title-card {
    padding: 2%;

    label {
      font-weight: 600;
      font-size: 18px;
      line-height: 20px;
      color: var(--q-primary);
    }
  }

  h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 30px;
    color: #686868;
  }

  h5 {
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #686868;
  }

  h6 {
    font-weight: 600;
    font-size: 8px;
    line-height: 12px;
    color: #686868;
  }
}

.vue-grid-layout {
  background: #fff;
}

.vue-grid-item:not(.vue-grid-placeholder) {
  background: #ccc;
}

.vue-grid-item .resizing {
  opacity: 0.9;
}

.vue-grid-item .static {
  background: #cce;
}

.vue-grid-item .text {
  font-size: 24px;
  text-align: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 100%;
  width: 100%;
}

.vue-grid-item .no-drag {
  height: 100%;
  width: 100%;
}

.vue-grid-item .minMax {
  font-size: 12px;
}

.vue-grid-item .add {
  cursor: pointer;
}

.vue-draggable-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><circle cx='5' cy='5' r='5' fill='#999999'/></svg>")
    no-repeat;
  background-position: bottom right;
  padding: 0 8px 8px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: pointer;
}

.card-top {
  width: 23.4%;
  height: 108px;
  background: #fbfbfb;
  background-repeat: no-repeat;
  background-size: 109%;
  background-position-y: 119%;
  border: 0.2px solid #dbdbdb;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.card-top-orange {
  background-image: url("../../assets/images/graph_orange.svg");
}

.card-top-red {
  background-image: url("../../assets/images/graph_red.svg");
}

.card-top-purple {
  background-image: url("../../assets/images/graph_purple.svg");
}

.card-top-green {
  background-image: url("../../assets/images/graph_green.svg");
}

.card-top-title {
  position: absolute;
  font-weight: 500;
  font-size: 22px;
  line-height: 20px;
}

.card-table {
  background: #fbfbfb;
  border: 0.2px solid #dbdbdb;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
}

.title-card {
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: var(--q-primary);
}

.subtitle-card {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: #686868;
}
</style>
